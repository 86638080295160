import React from "react"
import TileList from "../../components/tilelist"
import SubnavList from "../../components/subnavList"
import Profile from "../../components/writeups"

const writeupPage = () => {
  return (
    <div className="about">
      <div className="contentArea post">
        <Profile />
        <div className="largeContent">
          <TileList />
        </div>
      </div>
      <SubnavList />
    </div>
  )
}

export default writeupPage;